<template>
  <v-container class="pa-0 ma-0" fluid>
    <div class="d-flex" v-if="value && !loading">
      <div style="width: 50%" tile>
        <!-- <v-card-title>{{ $t("value") }}:</v-card-title> -->
        <v-list-item>
          <v-list-item-content>
            <!-- <v-list-item-title>{{ value.valueName }}</v-list-item-title>
            <v-list-item-subtitle>Id: {{ value.valueId }}</v-list-item-subtitle> -->

            <v-container fluid>
              <v-row>
                <v-col class="details-column-label">
                  <b>{{ $t("id") }}:</b>
                </v-col>
                <v-col>{{ value.customerId }}</v-col>
              </v-row>

              <v-row>
                <v-col class="details-column-label">
                  <b>{{ $t("name") }}:</b>
                </v-col>
                <v-col>{{ value.name }}</v-col>
              </v-row>

              <v-row>
                <v-col class="details-column-label">
                  <b>{{ $t("ownerToken") }}:</b>
                </v-col>
                <v-col>{{ value.ownerToken }}</v-col>
              </v-row>

              <v-row>
                <v-col class="details-column-label">
                  <b>{{ $t("_uri") }}:</b>
                </v-col>
                <v-col>{{ value._uri }}</v-col>
              </v-row>

              <v-row v-if="value.createdAt">
                <v-col class="details-column-label">
                  <b>{{ $t("createdAt") }}:</b>
                </v-col>
                <v-col>{{ value.createdAt | getDisplayDate }}</v-col>
              </v-row>

              <v-row v-if="value.createdBy?.name">
                <v-col class="details-column-label">
                  <b>{{ $t("createdBy") }}:</b>
                </v-col>
                <v-col>{{ value.createdBy.name | empty }}</v-col>
              </v-row>

              <v-row v-if="value.updatedAt">
                <v-col class="details-column-label">
                  <b>{{ $t("updatedAt") }}:</b>
                </v-col>
                <v-col>{{ value.updatedAt | getDisplayDate }}</v-col>
              </v-row>

              <v-row v-if="value.updatedBy?.name">
                <v-col class="details-column-label">
                  <b>{{ $t("updatedBy") }}:</b>
                </v-col>
                <v-col>{{ value.updatedBy.name | empty }}</v-col>
              </v-row>
            </v-container>

            <!-- {{ value }} -->
          </v-list-item-content>
        </v-list-item>
      </div>
      <div style="width: 50%" tile>
        <v-list-item>
          <v-list-item-content>
            <v-container fluid>
              <!-- <v-row>
                <v-col class="details-column-label">
                  <b>{{ $t("countryCode") }}:</b>
                </v-col>
                <v-col>{{ value.countryCode }}</v-col>
              </v-row> -->
              <v-row>
                <v-col class="details-column-label">
                  <b>{{ $t("country") }}:</b>
                </v-col>
                <v-col>{{ country }}</v-col>
              </v-row>
              <!-- <v-row>
                <v-col class="details-column-label">
                  <b>{{ $t("zipCode") }}:</b>
                </v-col>
                <v-col>{{ value.zipCode }}</v-col>
              </v-row>
              <v-row>
                <v-col class="details-column-label">
                  <b>{{ $t("city") }}:</b>
                </v-col>
                <v-col>{{ value.city }}</v-col>
              </v-row>
              <v-row>
                <v-col class="details-column-label">
                  <b>{{ $t("street") }}:</b>
                </v-col>
                <v-col>{{ value.street }}</v-col>
              </v-row> -->
              <v-row>
                <v-col class="details-column-label">
                  <b>{{ $t("address") }}:</b>
                </v-col>
                <v-col>{{ address }}</v-col>
              </v-row>
              <v-row>
                <v-col class="details-column-label">
                  <b>{{ $t("taxNumber") }}:</b>
                </v-col>
                <v-col>{{ value.taxNumber }}</v-col>
              </v-row>
              <v-row>
                <v-col class="details-column-label">
                  <b>{{ $t("email") }}:</b>
                </v-col>
                <v-col>{{ value.email }}</v-col>
              </v-row>
              <v-row>
                <v-col class="details-column-label">
                  <b>{{ $t("mobile") }}:</b>
                </v-col>
                <v-col>{{ value.mobile }}</v-col>
              </v-row>
              <v-row>
                <v-col class="details-column-label">
                  <b>{{ $t("locale") }}:</b>
                </v-col>
                <v-col>{{ locale }}</v-col>
              </v-row>
              <v-row>
                <v-col class="details-column-label">
                  <b>{{ $t("ownedBy") }}:</b>
                </v-col>
                <v-col>{{ value.ownedBy?.name | empty }}</v-col>
              </v-row>
              <v-row>
                <v-col class="details-column-label">
                  <b>{{ $t("mfCustomer") }}:</b>
                </v-col>
                <v-col>{{ value.mfCustomer?.customerName | empty }}</v-col>
              </v-row>
              <v-row>
                <v-col class="details-column-label">
                  <b>{{ $t("comment") }}:</b>
                </v-col>
                <v-col>{{ $t(value.comment) }}</v-col>
              </v-row>
            </v-container>
          </v-list-item-content>
        </v-list-item>
      </div>
    </div>
    <div v-if="!value && !loading">
      <span>{{ $t("no_data") }}</span>
    </div>
    <div class="text-center" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
        class="ma-4"
      ></v-progress-circular>
      <span>{{ $t("loading") }}</span>
    </div>
  </v-container>
</template>

<script>
const { callAPI, callBffAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  name: "GsCustomerDetails",
  components: {},
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    country() {
      return this.getCountryName(this.value.countryCode);
    },
    address() {
      const zipCode = this.value.zipCode ? this.value.zipCode : "";
      const city = this.value.city ? this.value.city : "";
      const street = this.value.street ? this.value.street : "";
      return `${zipCode} ${city} ${street}`;
    },
    locale() {
      return this.getLocaleName(this.value.locale);
    }
  }
};
</script>

<style scoped>
.details-column-label {
  width: 200px;
}
</style>
